<template>
    <div class="faq container mb-5">
        <faq-item-component v-for="(item) in faq"
                            :key="item.question"
                            :item="item"></faq-item-component>
    </div>
</template>


<script>
    import {mapActions, mapState} from "vuex";
    import FaqItemComponent from "./FaqItemComponent";

    export default {
        name: "FaqComponent",
        components: {FaqItemComponent},
        methods: {
            ...mapActions(['GET_FAQ']),
            getFaq() {
                this.GET_FAQ()
            }
        },
        computed: {
            ...mapState({
                faq: state => state.faq.faq
            }),
        },
        created() {
            this.getFaq()
        }
    }
</script>

<style lang="sass">

</style>