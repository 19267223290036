<template>
    <div class="faq-item">
        <div class="faq-question" @click="toogleItem">
            <i class="fa-question-circle" :class="visible? 'fas' : 'far'"></i>
            <div>{{ question }}</div>
        </div>
        <div v-show="visible" class="faq-answer" v-html="answer"></div>
        <hr>
    </div>
</template>

<script>
    export default {
        name: "FaqItemComponent",
        data() {
            return {
                visible: false,
                question: '',
                answer: '',
            }
        },
        methods: {
            toogleItem() {
                this.visible = !this.visible;
            }
        },
        props: {
            item: Object
        },
         mounted: function () {
            this.question = this.convertEditorJsonToHtml(this.item.question) || this.item.question;
            this.answer = this.convertEditorJsonToHtml(this.item.answer) || this.item.answer;
         },
    }
</script>

<style lang="scss" scoped>
    .faq-item {
        text-align: left;
        line-height: 1.5em;
    }

    .fa-question-circle {
        display: inline-block;
        margin-right: 1em;
    }

    .faq-question {
        font-weight: bold;

        > div {
            display: inline-block;
        }
    }

    .faq-answer {
        color: gray;
        margin-left: 2em;
        margin-top: 1.5em;

    }

</style>